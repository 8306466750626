import React from 'react';
import { Link } from 'gatsby';
import {
    HeaderContainer,
    LinkContainer,
    ToggleMenu,
    NavIcon, HeaderWrapper,
    MobileLinkWrapper, DropDownMenu, SubmenuWrapper, MenuBackdrop,
    MobileItemContainer
} from './Components';
import { Image, ImageContainer } from '../common/ui/Image'
import CustomLink from '../common/ui/CustomLink'
import CutomButton from '../common/ui/CutomButton'
import { logoSrc } from '../../constants/strings'
import { CustomDrawer } from '../common/ui/CustomDrawer';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import SubmenuCards from './SubmenuCards';
/**
* Header component
*
*/

class Header extends React.Component {

    constructor(props) {
        super();
        this.state = {
            menuOpen: false,
            submenuOpen: false,
            activeSubMenu: []
        }
    }

    toggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen })
    }

    addHover = (navSubMenu) => {
        if (navSubMenu instanceof Array) {
            this.setState({ submenuOpen: true, activeSubMenu: navSubMenu })
        } else {
            this.setState({ submenuOpen: true })
        }

    }

    removeHover = () => {
        this.setState({ submenuOpen: false })
    }

    onBtnClick = () => {
        if (window) {
            window.open('https://chqbook.app.link/adQEzb80L9', '_blank');
        }
    }

    render() {

        const {
            activeSubMenu
        } = this.state;

        const {
            header
        } = this.props;

        return (
            <>
                <HeaderContainer>
                    <HeaderWrapper>
                        <ImageContainer width='151px' sWidth="151px" mWidth="110px" alignSelfContainer='center'>
                            <Link to='/'>
                                <Image src={logoSrc} width='151px' />
                            </Link>
                        </ImageContainer>
                        <CustomDrawer
                            anchor='right'
                            open={this.state.menuOpen}
                            onClose={this.toggleMenu}
                            transitionDuration={600}
                        >
                            <MobileLinkWrapper>
                                {
                                    header.map((nav, i) => {
                                        if (!nav.pathname) {
                                            return (
                                                <div key={i}>
                                                    <Heading size='s' family='fontFamilyBold' sMargin='0 0 20px 0'>{nav.name}</Heading>
                                                    {
                                                        nav.subMenu && nav.subMenu.map((subnav, index) => {
                                                            return (
                                                                <CustomLink
                                                                    external={subnav.external}
                                                                    target={subnav.target}
                                                                    to={subnav.pathname}
                                                                    key={index}
                                                                >
                                                                    <MobileItemContainer>
                                                                        <ImageContainer
                                                                            imgSelfAlign='center'
                                                                            display='flex'
                                                                            width='30px'
                                                                        >
                                                                            <Image src={subnav.iconSrc}
                                                                                width={subnav.iconWidth}
                                                                            />
                                                                        </ImageContainer>
                                                                        <Text
                                                                            size='xl'
                                                                            color='MEDIUM_GREY'
                                                                            weight='medium'
                                                                        >{subnav.heading}</Text>
                                                                    </MobileItemContainer>
                                                                </CustomLink>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <CustomLink key={i} to={nav.pathname} external={nav.external} target={nav.target}>
                                                    <Heading size='xs' family='fontFamilyBold' sMargin='0 0 20px 0'> {nav.name}</Heading>
                                                </CustomLink>
                                            );
                                        }
                                    })
                                }
                            </MobileLinkWrapper>
                        </CustomDrawer>
                        <ToggleMenu>
                            <a onClick={this.toggleMenu}>
                                <NavIcon
                                    open={this.state.menuOpen}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </NavIcon>
                            </a>
                        </ToggleMenu>
                        <LinkContainer>
                            {
                                header.map((nav, i) => {
                                    if (!nav.pathname) {
                                        return (
                                            <CustomLink
                                                activeClassName="is-active"
                                                color='MEDIUM_GREY'
                                                size="lg"
                                                display='flex'
                                                height='70px'
                                                alignItem='center'
                                                key={i} external={true}
                                                borderBottom={`2px solid ${this.props.theme.colors.WHITE}`}
                                                activeBorderBottom={`2px solid ${this.props.theme.colors.CHQBOOK_BLUE}`}
                                                onMouseEnter={this.addHover.bind(null, nav.subMenu)}
                                                onClick={this.addHover.bind(null, nav.subMenu)}
                                                onMouseLeave={this.removeHover}
                                            >
                                                {nav.name}
                                            </CustomLink>
                                        )
                                    }
                                    else {
                                        return (
                                            <CustomLink
                                                activeClassName="is-active"
                                                display='flex'
                                                height='70px'
                                                alignItem='center'
                                                borderBottom={`2px solid ${this.props.theme.colors.WHITE}`}
                                                activeBorderBottom={`2px solid ${this.props.theme.colors.CHQBOOK_BLUE}`}
                                                color='MEDIUM_GREY' size="lg" key={i} external={nav.external}
                                                to={nav.pathname}
                                                target={nav.target}
                                            >
                                                {nav.name}
                                            </CustomLink>
                                        );
                                    }
                                })
                            }
                            <CutomButton
                                size="sm"
                                weight="bold"
                                onClick={this.onBtnClick}
                            >
                                GET THE CHQBOOK APP
                                <ImageContainer
                                    width='13px'
                                >
                                    <Image
                                        src='/images/icons/playstore-header-cta.png'
                                    />
                                </ImageContainer>
                                {/* <ImageContainer
                                    width='13px'
                                >
                                    <Image
                                        src='/images/icons/apple-header-cta.png'
                                    />
                                </ImageContainer> */}
                            </CutomButton>
                        </LinkContainer>
                    </HeaderWrapper>
                </HeaderContainer>
                <DropDownMenu
                    submenuOpen={this.state.submenuOpen}
                    onMouseEnter={this.addHover}
                    onMouseLeave={this.removeHover}>
                    <SubmenuWrapper>
                        {
                            activeSubMenu.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <SubmenuCards item={item} theme={this.props.theme} />
                                    </div>
                                );
                            })
                        }
                    </SubmenuWrapper>
                </DropDownMenu>
                <MenuBackdrop submenuOpen={this.state.submenuOpen} />
            </>
        );
    }
}


export default Header;
