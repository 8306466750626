const theme = {
    fontFamily: '"Noto Sans", sans-serif',
    fontFamilyBold: '"Noto Sans Bold", sans-serif',
    backgroundColor: 'white',
    colors: {
        CHQBOOK_BLUE: '#19447a',
        CHQBOOK_RED: '#e02729',
        CHQBOOK_BLUE_HOVER: '#103661',
        TRANSPARENT: 'transparent',
        BLACK: '#000000',
        MEDIUM_BLACK: '#111111',
        WHITE: '#ffffff',
        DARK_GREY: '#222222',
        MEDIUM_GREY: '#414141',
        LIGHT_GREY: '#f7f7f7',
        LIGHTER_GREY: '#707070',
        HEADER_ICON_HIGHLIGHT: 'rgba(217, 36, 42, 0.02)',
        ERROR_MESSAGE: '#FFE5E5',
        SUCCESS_MESSAGE: '#ECFFE1',
        ABOUT_DESCRP: '#383838',
        ABOUT_FOUNDER_NAME: '#001c3d',
        TAG: '#e82e2f',
        BACKGROUND_PLACEHOLDER:'#f5f5f5',
        CHQBOOK_BLUE_LIGHT: '#f2f8fe',
        CHQBOOK_BLUE_DARK: '#14467d'
    },
    web: {
        text: {
            size: {
                xxxs: '10px',
                xxs: '12px',
                xs: '13px',
                sm: '14px',
                xmd: '15px',
                md: '16px',
                lg: '18px',
                xl: '20px',
                xxl: '22px',
                xxxl: '24px',
            },
            weight: {
                light: '300',
                normal: '400',
                medium: '500',
                large: '600',
                bold: 'bold',
            }
        },
        heading: {
            size: {
                xxxxs: '18px',
                xxxs: '20px',
                xxs: '22px',
                xs: '28px',
                s: '29px',
                sm: '30px',
                xmd: '32px',
                md: '34px',
                lg: '38px',
                xl: '40px',
                xxl: '46px',
                xxxl: '48px'
            },
            weight: {
                light: '400',
                normal: '600',
                medium: '700',
                bold: '800',
            }
        },
        borderRadius: {
            normal: '2px',
            round: '50%',
        },
        padding: {
            xs: '12px',
            sm: '16px',
            md: '24px',
            lg: '32px',
            xl: '36px',
            xxl: '40px',
        }
    },
    mobile: {
        text: {
            size: {
                xxxs: '10px',
                xxs: '12px',
                xs: '10px',
                sm: '14px',
                md: '14px',
                xmd: '15px',
                lg: '16px',
                xl: '18px',
                xxl: '20px',
                xxl: '17px',
                xxxl: '18px'
            },
            weight: {
                light: '300',
                normal: '400',
                medium: '500',
                large: '600',
                bold: 'bold',
            }
        },
        heading: {
            size: {
                xxxxs:'17px',
                xxxs: '14px',
                xxs: '16px',
                xs: '18px',
                s: '20px',
                sm: '24px',
                xmd: '25px',
                md: '26px',
                lg: '30px',
                xl: '28px',
                xxl: '36px',
                xxxl: '36px'
            },
            weight: {
                light: '400',
                normal: '600',
                medium: '700',
                bold: '800',
            }
        },
        borderRadius: {
            normal: '4px',
            round: '50%',
        },
        padding: {
            xs: '8px',
            sm: '16px',
            md: '24px',
            lg: '32px',
            xl: '36px',
            xxl: '40px',
        }
    },
};

export default theme;

