import React from 'react'
import styled from 'styled-components';
import { deviceLessThan } from '../../../styles/breakpoints';

export const Image = styled.img`
    src: ${props => props.src};
    width: ${props => props.width};
    alt: ${props => props.alt};
    border-radius:${props => props.borderRadius ? props.borderRadius : ""};
    transform:${props => props.transform && props.transform};
    padding:${props => props.padding && props.padding};
`;

export const ImageContainer = styled.div`
    align-self: ${props => props.alignSelfContainer};
    min-height:${props => props.minHeight};
    right:${props => props.right};
    top:${props => props.top};
    position:${props => props.position};
    width: ${props => props.width ? props.width : '100%'};
    padding:${props => props.padding};
    display:${props => props.display ? props.display : 'block'};
    max-width:${props => props.maxWidth && props.maxWidth};
    margin: ${props => props.margin ? props.margin : '0'};
    height: ${props => props.height && props.height};/* height of container */
    overflow: ${props => props.overflow && props.overflow};
    overflow-y: ${props => props.overflowY && props.overflowY};
    text-align: ${props => props.textAlign && props.textAlign};
    justify-content: ${props => props.jContent && props.jContent};
    flex-direction:${props => props.flexDirection && props.flexDirection};
    transform: ${props => props.transform && props.transform};
    cursor:${props => props.cursor};
    vertical-align:${props => props.verticalAlign};
    flex:${props => props.flex && props.flex};
    background-color:${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.TRANSPARENT};
    border-radius:${props => props.borderRadius && props.borderRadius};
    & > img {
            align-self: ${props => props.imgSelfAlign};
    };
    &:hover{
        background-color:${props => props.hoverBackgroundColor ? props.theme.colors[props.hoverBackgroundColor] : 'none'};
    }
    &:hover > div {
        opacity:${props => props.opacityDiv};
    }
    &:hover > img {
        opacity:${props => props.opacityImage};
    }
    /* below laptop breakpoint */
    @media ${deviceLessThan.laptop} {
        display: ${props => props.mDisplay && props.mDisplay};
        width: ${props => props.mWidth && props.mWidth};
        margin: ${props => props.mMargin && props.mMargin};
        & > img {
            align-self: ${props => props.mImgSelfAlign};
        }
    }

    /* below tablet breakpoint */
    @media ${deviceLessThan.tablet} {
        padding:${props => props.sPadding};
        display: ${props => props.sdisplay && props.sdisplay};
        width: ${props => props.sWidth && props.sWidth};
        max-width: ${props => props.sMaxWidth && props.sMaxWidth};
        margin: ${props => props.sMargin && props.sMargin};
        height: ${props => props.sHeight && props.sHeight};/* height of container */
        font-size: ${p => p.size ? p.theme.web.text.size[p.size] : p.theme.web.text.size.md};
    }
    @media ${deviceLessThan.mobileM} {
        margin: ${props => props.xsMargin && props.xsMargin};
    }
`;
