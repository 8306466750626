import React from 'react'
import styled from 'styled-components';
import { deviceLessThan } from '../../../styles/breakpoints';
import { appStore, playStore } from '../../../constants/strings'
import { Image, ImageContainer } from './Image';

const BadgeContainer = styled.div`
    width: ${props => props.width ? props.width : "280px"};
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction:row;
    margin:${props => props.margin && props.margin};
    justify-content:${props => props.justifyContent ? props.justifyContent : 'space-around'};

    @media ${deviceLessThan.laptop} {
        display: ${props => props.mDisplay};
        width: 275px;
        margin: 0 auto;
    }

    @media ${deviceLessThan.tablet} {
        display: ${props => props.sDisplay};
    }

    @media ${deviceLessThan.mobileS} {
        display: ${props => props.xsDisplay};
        width: 275px;
        margin: 0 auto;
    }
`;

const BadgeWrapper = ({ appLink, marginLeft, marginRight, ...otherProps }) => {

    return (
        <BadgeContainer {...otherProps}>
            <a href={appLink} target='_blank'>
                <ImageContainer margin={marginRight} width='117px' cursor='pointer'>
                    <Image src={playStore} />
                </ImageContainer>
            </a>
            {/* <a href={appLink} target='_blank'>
                <ImageContainer margin={marginLeft} width='117px' cursor='pointer'>
                    <Image src={appStore} />
                </ImageContainer>
            </a> */}
        </BadgeContainer>
    )
}

export default BadgeWrapper;
