import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';
import CustomLink from '../common/ui/CustomLink';

/**
* HeaderContainer
*
*/

export const HeaderContainer = styled.header`
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: space-around;
    background-color: ${props => props.theme.colors.WHITE};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    height: 70px;
    width: 100%;
    z-index: 1000;
    @media ${deviceLessThan.tablet} {
        justify-content: space-between;
        padding: 0 30px;
    }
`;

export const HeaderWrapper = styled.div`
    width: 75%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    @media ${deviceLessThan.laptopM} {
        width: 80%
    }
    @media ${deviceLessThan.laptop} {
        width: 95%
    }
    @media ${deviceLessThan.tablet} {
        width: 100%
    }
`;

/**
* Links
*
*/

export const LinkContainer = styled.div`
    display: flex;
    width: max-content;
    justify-content: space-around;
    align-items: center;
    height: 70px;

    @media ${deviceLessThan.tablet} {
        display: none;
        height: unset;
    }

    & > a {
        margin-right: 35px;
        @media ${deviceLessThan.laptopM} {
            margin-right: 20px;
    }
        @media ${deviceLessThan.laptop} {
            margin-right: 3vw;
        }
        @media ${deviceLessThan.laptopS} {
            margin-right: 2vw;
        }
    }
`;

export const ToggleMenu = styled.div`
    display: none;
    @media ${deviceLessThan.tablet} {
        display: inline-block;
        align-self: flex-end;
    }
`;

export const HamburgerDiv = styled.div`
    background-color:${props => props.theme.colors.CHQBOOK_BLUE};
    width: 23px;
    height: 2px;
    margin-bottom: ${props => props.marginbottom};
`;

export const NavIcon = styled.div`
    width: 30px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 2002;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #19447a;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.8s ease;
      &:nth-child(1){
        top: ${p => p.open ? '-3px' : '0px'};
        transform-origin: left center;
        transform: ${p => p.open && `rotate(45deg)`};
        left: ${p => p.open && '8px'};
      }
      &:nth-child(2){
        top: 10px;
        transform-origin: left center;
        width: ${p => p.open && '0px'};
        opacity: ${p => p.open && '0'};
      }
      &:nth-child(3){
        top: ${p => p.open ? '19px' : '20px'};
        transform-origin: left center;
        transform: ${p => p.open && `rotate(-45deg)`};
        left: ${p => p.open && '8px'};
      }
    }
`;

export const MobileLinkWrapper = styled.div`
    padding: 25px 0px 0px 25px;
`;

export const DropDownMenu = styled.div`
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    transition: 0.5s ease-in-out;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    width: 100%;
    position: fixed;
    z-index: 300;
    left: auto;
    top: 70px;
    overflow: auto;
    right: 0px;
    opacity: ${p => p.submenuOpen ? '1' : '0'};
    visibility: ${p => p.submenuOpen ? 'visible' : 'hidden'};
    &:hover{
      opacity: 1;
      visibility: visible;
    }
`;

export const SubmenuWrapper = styled.div`
    width: 75%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px 0 25px 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    @media ${deviceLessThan.laptop}{
        width: 100%;
        padding: 0 20px;
    }
`;

export const MenuBackdrop = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 70px;
    left: 0px;
    opacity: 1;
    background-color: rgba(17, 17, 17, 0.4);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity;
    transform: translateZ(0px);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 200;
    pointer-events: auto;
    visibility: ${p => p.submenuOpen ? 'visible' : 'hidden'};
`

export const HeaderCustomLink = styled((props) => <CustomLink {...props} />)`
    &:hover {
        border-bottom: ${props => props.activeBorderBottom};
        background-color: ${props => props.activeBackgroundColor};
        & > div:last-child {
            & > p:first-child {
                color: ${props => props.theme.colors.CHQBOOK_BLUE};
            }
            & > p:last-child {
                color: ${props => props.theme.colors.MEDIUM_BLACK};
            }
        }
    }
    &.${props => props.activeClassName}{
        border-bottom: ${props => props.activeBorderBottom};
        background-color: ${props => props.activeBackgroundColor};
        & > div:last-child {
            & > p:first-child {
                color: ${props => props.theme.colors.CHQBOOK_BLUE};
            }
            & > p:last-child {
                color: ${props => props.theme.colors.MEDIUM_BLACK};
            }
        }
    }
`;

export const MobileItemContainer = styled.div`
    display: flex;
    margin: 0 0 30px 0;
`;
