import React from 'react'
import PropTypes from 'prop-types'
import { Image, ImageContainer } from '../common/ui/Image';
import Text from '../common/ui/Text';
import { HeaderCustomLink } from './Components';

const SubmenuCards = props => {
    const {
        iconSrc = '',
        iconWidth = '18px',
        pathname = '',
        heading = '',
        subHeading = '',
        external = false
    } = props.item;

    return (
        <div>
            <HeaderCustomLink
                activeClassName="is-active"
                external={external}
                to={pathname}
                display='flex'
                padding='31px 20px'
                backgroundColor='WHITE'
                width='250px'
                minHeight='165px'
                margin='0 0 10px 7px'
                borderBottom={`2px solid ${props.theme.colors.WHITE}`}
                activeBorderBottom={`2px solid ${props.theme.colors.CHQBOOK_BLUE}`}
                activeBackgroundColor={props.theme.colors.HEADER_ICON_HIGHLIGHT}
                transition='0.1s ease'
            >
                <ImageContainer width='20%'>
                    <Image src={iconSrc}
                        width={iconWidth}
                    />
                </ImageContainer>
                &nbsp;&nbsp;
                <div>
                    <Text size='lg' weight='medium' color='MEDIUM_BLACK'>{heading}</Text>
                    <Text
                    size='xxs'
                    color='LIGHTER_GREY'
                    lineHeight='1.5'
                    margin='5px 0 0 0'
                    >{subHeading}</Text>
                </div>
            </HeaderCustomLink>
        </div>
    )
}

SubmenuCards.propTypes = {

}

export default SubmenuCards
