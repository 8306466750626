import { navigate } from 'gatsby';

export const getJsonFromString = (rawString) => {
    let sanitizedData = null;

    try {
        let componentData = rawString;
        componentData = componentData?.replace(/(\r\n|\n|\r)/gm, " "); //removing all kinds of possible newline characters
        sanitizedData = componentData ? JSON.parse(componentData) : null;
    } catch (err) {
        console.log('JSON parse error', err)
        sanitizedData = null;
    }

    return sanitizedData;
}

export const modifyContent = data => {
    let pageContent = data;
    let objData = {};
    for (const key in pageContent) {
        try {
            let sanitized = getJsonFromString(pageContent[key].nodes[0].plaintext);
            objData[`${key}`] = sanitized;
        } catch (err) { }
    }
    return objData;
}

export const downloadImage = (img, name = "Download") => {
    return fetch(img)
        .then(response => {
            return response.blob()
        })
        .then(blob => {
            let imageType = blob.type.split("/")[1];
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${name}.${imageType}`;
            a.click();
        });
}

export const onButtonClick = (link, external = false) => {
    try {
        if (typeof (link) == "string" && link.includes('http')) {
            window.open(link, '_blank');
        }
        else if (external) {
            window.open(process.env.BASE_URI + link, '_self')
        } else {
            navigate(link);
        }
    } catch (err) {
        console.log('break', err)
    }
}

export const mergeTwoArrays = (arr1, arr2) => {
    let arr = [];
    let arr1Length = arr1.length;
    let arr2Length = arr2.length;
    let arrLength = arr1Length > arr2Length ? arr1Length : arr2Length;

    for (let i = 0; i < arrLength; i++) {
        let obj = {};
        obj = { ...arr1[i], ...arr2[i] };
        arr = [...arr, obj];
    }

    return arr;
}

export const truncate = (str, wordCount, suffix = '') => {
    return str.split(" ").splice(0, wordCount).join(" ") + ` ${suffix}`;
}

export const featuredOrLatestPosts = (featured, latest, numOfPosts) => {
    try {
        return featured.length > 0 ?
            featured.length < numOfPosts ?
                featured.concat(latest.slice(0, latest.length - featured.length))
                : featured
            : latest
    } catch (err) {
        console.log('featuredOrLatest', err);
        return [];
    }
}

export const getOptimizedImageUrl = (url) => {
    try {
        return url.replace('q_auto', 'w_900,q_auto,f_auto')
    } catch (err) {
        return url
    }
}
