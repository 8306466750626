import React from 'react';
import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';
/**
* FooterContainer
*
*/

export const FooterContainer = styled.footer`
    width: 100%;
    padding:44px 0px 14px 0px;
    background: ${ props => props.theme.colors.LIGHT_GREY} url('/images/footer-pattern.png') right no-repeat;
    background-size: auto 203px;

    /* below laptop breakpoint */
    @media ${deviceLessThan.laptop} {
        padding: 21px 0px 17px 20px;
        background-size: auto 134px;
        background-position: 100% 20%;
    }
`;

export const FooterWrapper = styled.div`
    width: 75%;
    max-width: 1200px;
    display:block;
    margin: 0 auto;
    display: flex;
        /* below laptop breakpoint */
    @media ${deviceLessThan.laptop} {
        flex-direction: column;
        width: 100%;
    }

    & > div:first-child {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        width:40%;
    }
    & > div:last-child {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        width: 100%
    }
`;

/**
* Logos and Badges
*
*/


export const BadgeWrapper = styled.div`
    width: ${props => props.width ? props.width : "280px"};
    display: ${ props => props.hiddenDesktop ? 'none':'flex'};
    flex-direction:row;
    margin:${props => props.margin && props.margin};
    justify-content:${props => props.justifyContent ? props.justifyContent: 'space-around' };

    @media ${deviceLessThan.laptop} {
        display: ${ props => props.hiddenMobile ? 'none':'flex'};
        width: 100%;
        padding: 0px 46px;
    }

    @media ${deviceLessThan.tablet} {
        display: ${ props => props.sDisplay};
    }

    @media ${deviceLessThan.mobileS} {
        display: ${ props => props.hiddenMobile ? 'none':'flex'};
        width: 100%;
        padding: 0px 20px;
    }
`;


/**
* Footer Links
*
*/

export const LinksWrapper = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
        width: 170px;
        display: flex;
        flex-direction: column;
        color: red;

    @media ${deviceLessThan.mobileM} {
        width:145px;
    }
    }
`

export const FooterBottomLinksWrapper = styled.div`
    width: ${props => props.width && props.width};
    display: flex;
    justify-content: ${props => props.justifyContent && props.justifyContent};
    margin-top: 40px;
    @media ${deviceLessThan.laptop} {
        width: ${props => props.mWidth && props.mWidth};
        justify-content: ${props => props.mJustifyContent && props.mJustifyContent};
        padding: ${props => props.mPadding && props.mPadding};
        margin: ${props => props.mMargin && props.mMargin};
        align-self: ${props => props.mSelfAlign};
    }
    @media ${deviceLessThan.mobileM} {
        padding: ${props => props.sPadding && props.sPadding};
    }
    @media ${deviceLessThan.mobileL} {
        flex-direction:${props => props.xsFlexDirection && props.xsFlexDirection};
    }
    & > div {
        line-height: ${props => props.lineHeight && props.lineHeight};
    }
`

export const DotWrap = styled.div`
    @media ${deviceLessThan.mobileL} {
        display:none;
    }
`;