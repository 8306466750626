/**
 *
 * Text
 *
 */

import React from 'react';
import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const Text = styled.p`
  display:${p => p.display};
  font-family: ${p => p.family ? p.theme[p.family] : p.theme.fontFamily};
  font-size: ${p => p.size ? p.theme.web.text.size[p.size] : p.theme.web.text.size.md};
  font-weight: ${p => p.weight ? p.theme.web.text.weight[p.weight] : p.theme.web.text.weight.light};
  color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.CHQBOOK_BLUE};
  text-align: ${p => p.align};
  opacity: ${props => props.opacity ? props.opacity : 1};
  max-width:${props => props.width && props.width};
  width:${p => p.tWidth};
  margin:${p => p.margin ? `${p.margin}` : 0};
  position: ${p => p.position};
  right: ${p => p.right};
  left: ${p => p.left};
  border:${p => p.border};
  border-radius:${p => p.borderRadius};
  cursor:${p => p.cursor};
  padding:${p => p.padding};
  top: ${p => p.top};
  line-height:${p => p.lineHeight};
  letter-spacing:${p => p.letterSpacing};
  background: ${p => p.background};
  z-index: ${p => p.zIndex};
  height:${p => p.height};
  cursor: ${p => p.cursor};
  border-bottom:${p => p.borderBottom};
  border-right:${p => p.borderRight};
  border-top:${p => p.borderTop};
  align-self: ${p => p.alignSelf};
  &:last-of-type{
    border-right:${p => p.lotBorderRight ? p.lotBorderRight :'none' };
    padding:${p => p.lastPadding}
  }
    @media ${deviceLessThan.laptop} {
        max-width:${p => p.width};
        overflow:${props => props.overflow && props.overflow};
        display: ${props => props.mDisplay && props.mDisplay};
        font-size: ${p => p.size ? p.theme.mobile.text.size[p.size] : p.theme.mobile.text.size.md};
        font-weight: ${p => p.weight ? p.theme.mobile.text.weight[p.weight] : p.theme.mobile.text.weight.light};
    }
    @media ${deviceGreaterThan.laptop} {
        display: ${props => props.wDisplay && props.wDisplay};
    }
    @media ${deviceLessThan.tablet} {
        display:${p => p.sDisplay};
        margin:${props => props.sMargin ? props.sMargin : '0'};
        padding:${p => p.sPadding};
        width:${p => p.widthm};
        text-align:${p => p.sAlign};
        opacity: ${props => props.sOpacity};

    }
    @media ${deviceLessThan.mobileM} {
        width:${p => p.widthm};
    }
`

export default Text;
