import React from "react";
import { Link } from "gatsby";
import {
    FooterContainer,
    FooterWrapper,
    LinksWrapper,
    FooterBottomLinksWrapper,
    DotWrap,
} from "./Components";
import { Image, ImageContainer } from "../common/ui/Image";
import { logoSrc, companyRegisteredName } from "../../constants/strings";
import Text from "../common/ui/Text";
import { FooterBottomSocialNav } from "../../constants/appConstant";
import CustomLink from "../common/ui/CustomLink";
import Divider from "../common/ui/Divider";
import BadgeWrapper from "../common/ui/BadgeWrapper";

/**
 * Footer component
 *
 */

const Footer = (props) => {
    const { siteUrl = "", footer = {} } = props;
    let { footerBottomNav = [] } = props;

    footerBottomNav = footerBottomNav.filter(
        (nav) =>
            (typeof window !== "undefined" &&
                nav.visibleOnRoutes &&
                nav.visibleOnRoutes.findIndex(
                    (route) => route === window.location.pathname
                ) !== -1) ||
            !nav.visibleOnRoutes
    );

    return (
        <FooterContainer>
            <FooterWrapper>
                <div>
                    <div>
                        <ImageContainer
                            margin="0 0 49px 12px"
                            sWidth="131px"
                            mMargin="0 0 49px 0"
                            width="145px"
                        >
                            <Image src={logoSrc} />
                        </ImageContainer>
                        <BadgeWrapper
                            margin='0 0 0 19px'
                            justifyContent="flex-start"
                            mDisplay="none"
                            appLink="https://chqbook.page.link/app"
                        />
                    </div>
                    <Text
                        mDisplay="none"
                        weight="normal"
                        size="sm"
                        opacity={0.6}
                        color="DARK_GREY"
                    >
                        {companyRegisteredName}
                    </Text>
                </div>
                <div>
                    <LinksWrapper>
                        {Object.keys(footer).map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div>
                                        <Text
                                            family="fontFamilyBold"
                                            weight="bold"
                                            size="sm"
                                            margin="20"
                                            sMargin="20"
                                        >
                                            {item}
                                        </Text>
                                        {footer[item].map((nav, index) => {
                                            return (
                                                <CustomLink
                                                    margin="0 0 15px 0"
                                                    to={
                                                        nav.external
                                                            ? nav.pathname.startsWith(
                                                                  "/"
                                                              )
                                                                ? siteUrl +
                                                                  nav.pathname
                                                                : nav.email
                                                                ? "mailto:" +
                                                                  nav.pathname
                                                                : nav.pathname
                                                            : nav.pathname
                                                    }
                                                    key={index}
                                                    weight="normal"
                                                    size="sm"
                                                    opacity={0.8}
                                                    external={nav.external}
                                                    target={nav.target}
                                                    email={nav.email}
                                                >
                                                    {nav.name}
                                                </CustomLink>
                                            );
                                        })}
                                    </div>
                                    {
                                        //for every odd item
                                        i != 0 && i % 2 != 0 && <Divider />
                                    }
                                </React.Fragment>
                            );
                        })}
                    </LinksWrapper>
                    <BadgeWrapper
                        justifyContent="flex-start"
                        margin='0 0 0 19px'
                        display="none"
                        mDisplay="flex"
                        appLink="https://chqbook.page.link/app"
                    />
                    <FooterBottomLinksWrapper
                        mJustifyContent="space-evenly"
                        width="max-content"
                        mSelfAlign="center"
                    >
                        {FooterBottomSocialNav.map((nav) => {
                            return (
                                <ImageContainer
                                    key={nav.id}
                                    width="25px"
                                    mWidth="20px"
                                    margin="0 40px 0 0"
                                    mMargin="0 28px 0 0"
                                >
                                    <a href={nav.url} target="_blank">
                                        <Image src={nav.imgSrc} />
                                    </a>
                                </ImageContainer>
                            );
                        })}
                    </FooterBottomLinksWrapper>
                    <Text
                        wDisplay="none"
                        weight="normal"
                        size="sm"
                        opacity={0.6}
                        color="DARK_GREY"
                        margin="28px 0 0 0"
                        sMargin="20px 0 0 0"
                    >
                        {companyRegisteredName}
                    </Text>
                    <FooterBottomLinksWrapper
                        lineHeight="normal"
                        width="max-content"
                        justifyContent="space-between"
                        xsFlexDirection="column"
                        mMargin="0"
                        sPadding="0 5px"
                    >
                        {footerBottomNav.map((nav, item) => {
                            return (
                                <React.Fragment key={item}>
                                    <CustomLink
                                        to={
                                            nav.external
                                                ? nav.pathname.startsWith("/")
                                                    ? siteUrl + nav.pathname
                                                    : nav.email
                                                    ? "mailto:" + nav.pathname
                                                    : nav.pathname
                                                : nav.pathname
                                        }
                                        weight="normal"
                                        size="sm"
                                        opacity={0.6}
                                        target={nav.target}
                                    >
                                        {nav.name}
                                    </CustomLink>
                                    {item != footerBottomNav.length - 1 && (
                                        <DotWrap> &nbsp;.&nbsp;</DotWrap>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </FooterBottomLinksWrapper>
                </div>
            </FooterWrapper>
        </FooterContainer>
    );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
