export const FooterBottomSocialNav = [
    {
        id: 'linkedin',
        imgSrc: '/images/icons/linkedin.png',
        url: 'https://goo.gl/HEfUQi',
    },
    {
        id: 'twitter',
        imgSrc: '/images/icons/twitter.png',
        url: 'https://goo.gl/qtRDS0'
    },
    {
        id: 'youtube',
        imgSrc: '/images/icons/youtube.png',
        url: 'https://www.youtube.com/channel/UCLm96IxXOp78erD2r7oXzEw'
    },
    {
        id: 'fb',
        imgSrc: '/images/icons/fb.png',
        url: 'https://goo.gl/CcRo0I'
    },
    {
        id: 'instagram',
        imgSrc: '/images/icons/instagram.png',
        url: 'https://www.instagram.com/mychqbook/'
    }
];

export const allYears = [
    {
        id:1,
        year:"2020"
    },
    {
        id:2,
        year:"2019"
    },
    {
        id:3,
        year:"2018"
    },
    {
        id:4,
        year:"2017"
    },
]

export const allMonths = [
    {
        id:1,
        month:'January'
    },
    {
        id:2,
        month:'February'
    },
    {
        id:3,
        month:'March'
    },
    {
        id:4,
        month:'April'
    },
    {
        id:5,
        month:'May'
    },
    {
        id:6,
        month:'June'
    },
    {
        id:7,
        month:'July'
    },
    {
        id:8,
        month:'August'
    },
    {
        id:9,
        month:'September'
    },
    {
        id:10,
        month:'October'
    },
    {
        id:11,
        month:'November'
    },
    {
        id:12,
        month:'December'
    },
]

export const PROCUCT_ID_CC = 3;
export const PROCUCT_ID_LOAN = 2;
