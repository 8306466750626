import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

// Styles
import '../../styles/app.css'
import theme from '../../styles/theme';
import { ThemeProvider } from 'styled-components';
import Header from '../Header'
import Footer from '../Footer'
import { getJsonFromString } from '../../utils/helpers'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome, noFooter = false, noHeader = false }) => {
    const site = data.allGhostSettings.edges[0].node
    const metadata = data.site.siteMetadata;

    let navigation = data.navigation.edges[0]?.node.plaintext;
    const sanitizedNavigation = getJsonFromString(navigation);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Helmet>
                    {
                        //[@INFO: This condition has been added since in development we get blank pages on navigation due to this meta tag]
                        process.env.NODE_ENV !== 'development' &&
                        //[@INFO: This meta tag has been added to upgrade all the insecure requests to secured once]
                        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
                    }
                    <html lang={site.lang} />
                    <style type="text/css">{`${site.codeinjection_styles}`}</style>
                    <body className={bodyClass} />
                </Helmet>

                <div className="viewport">
                    <div className="viewport-top">
                        {/* The main header section on top of the screen */}
                        {
                            !noHeader &&
                            <Header theme={theme} header={sanitizedNavigation ? sanitizedNavigation.header : []} />
                        }
                        <main className={!noHeader ? "site-main" : ''}>
                            {/* All the main content gets inserted here, index.js, post.js */}
                            {children}
                        </main>

                    </div>
                    {
                        !noFooter &&
                        <div className="viewport-bottom">
                            {/* The footer at the very bottom of the screen */}
                            <Footer
                                siteUrl={metadata.siteUrl}
                                footer={sanitizedNavigation ? sanitizedNavigation.footer : {}}
                                footerBottomNav={sanitizedNavigation ? sanitizedNavigation.footerBottomNav : []}
                            />
                        </div>
                    }
                </div>

            </>
        </ThemeProvider>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                site{
                    siteMetadata{
                      siteUrl
                    }
                  }
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                navigation: allGhostPage(
                        filter: {
                            tags: {
                        elemMatch: {
                            name: {
                            eq: "#navigation"
                            }
                        }
                        }
                    }
                ){
                edges{
                node{
                    plaintext,
                }
                }
            }
        }
     `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
