/**
* Image Sources
*
*/

export const logoSrc = 'https://res.cloudinary.com/chqbook/image/upload/q_auto/v1550643436/logos/final_logo_chqbook.png';
export const appStore = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1600029695/chqbookWeb/5-10kb/app-store-badge_2x.png';
export const playStore = 'https://res.cloudinary.com/chqbook/image/upload/v1680510229/get-it-on-google-play-badge_owfucn.png';

export const dotPatternHero = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png';
export const hexagonFill = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575511/chqbookWeb/AboutUs/hexagon-fill_3x.png';
export const hexagonNoFill = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575403/chqbookWeb/AboutUs/hexagon-no-fill_3x.png';
export const downArrow = 'https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg';
export const redUnderLine = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1602189754/chqbookWeb/0-5kb/red-underline.png';
export const downloadIcon = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1604591410/chqbookWeb/0-5kb/download-icon_3x.png';
export const boxPattern = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1606209256/chqbookWeb/MediaCenter/MediaKit/box-pattern_3x.png';
export const ovalBackground = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1606389579/chqbookWeb/career/oval-banner_3x.png';
export const faceLogo = 'https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1613551178/chqbookWeb/Icons/Face-Logo_1.png';
/**
* Texts
*
*/

export const companyRegisteredName = '© Nineroot Technologies Pvt. Ltd';

export const themeMode = 'am=1'

export const fbAppId = '657603518355900'

export const fbDomainVerificationCode = 'cbck4hc35yepzpa99mleks6cpfqufd'


/**
* App Routes
*
*/

export const tagRouteBaseUrl = '/blog'
export const opinionRouteBaseUrl = '/media-center'

export const twitterHandle = 'https://twitter.com/chqbook'
