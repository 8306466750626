import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components'
import { deviceLessThan } from '../../../styles/breakpoints';

const sharedStyle = css`
    font-family: ${p => p.family ? p.theme[p.family] : p.theme.fontFamily};
    display: ${props => props.display};
    flex-direction:${props => props.flexDirection};
    height: ${props => props.height};
    align-items: ${props => props.alignItem};
    flex-direction:${props => props.flexDirection};
    font-weight:  ${props => props.weight ? props.theme.web.text.weight[props.weight] : props.theme.web.text.weight.medium};
    font-size:  ${props => props.size ? props.theme.web.text.size[props.size] : props.theme.web.text.size.sm};
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.DARK_GREY};
    opacity: ${props => props.opacity ? props.opacity : 1};
    cursor:pointer;
    width: ${props => props.width};
    padding:${props => props.padding};
    text-decoration: none !important;
    margin: ${props => props.margin && props.margin};
    background-color: ${props => props.backgroundColor && props.theme.colors[props.backgroundColor]};
    min-width: ${props => props.minWidth};
    min-height: ${props => props.minHeight};
    border-bottom: ${props => props.borderBottom};
    border-radius: ${props => props.borderRadius};
    border: ${props => props.border};
    transition: ${props => props.transition};
    &:hover {
        border-bottom: ${props => props.activeBorderBottom};
        background-color: ${props => props.activeBackgroundColor};
    }
    &.${props => props.activeClassName}{
        border: ${props => props.activeBorder};
        border-bottom: ${props => props.activeBorderBottom};
        background-color: ${props => props.activeBackgroundColor};
        color: ${props => props.activeColor && props.theme.colors[props.activeColor]};
        font-weight: ${props => props.activeWeight && props.theme.web.text.weight[props.activeWeight]};
    }
    @media ${deviceLessThan.laptop} {
        font-weight:  ${props => props.weight ? props.theme.mobile.text.weight[props.weight] : props.theme.mobile.text.weight.medium};
        font-size:  ${props => props.size ? props.theme.mobile.text.size[props.size] : props.theme.mobile.text.size.sm};
        width: ${props => props.mWidth};
    };
    @media ${deviceLessThan.laptopS} {
        padding:${props => props.padding};
    };
    @media ${deviceLessThan.tablet} {
        margin:${props => props.sMargin};
    };
`

const Internal = styled(({
    alignItem,
    borderBottom,
    activeBorderBottom,
    activeBackgroundColor,
    minWidth,
    minHeight,
    backgroundColor,
    mWidth,
    activeColor,
    activeWeight,
    sMargin,
    borderRadius,
    activeBorder,
    ...others
}) => <Link {...others} />)`
    ${sharedStyle}
`;

const Anchor = styled.a`
    ${sharedStyle};
`

const CustomLink = ({ external, target = '_self', ...otherProps }) => {
    const {
        to = ''
    } = otherProps

    return (
        (external || to?.includes('http'))
            ? <Anchor href={otherProps.to} target={target} {...otherProps}>{otherProps.children}</Anchor>
            : to
                ? <Internal {...otherProps} to={`${to}/`} >{otherProps.children}</Internal>
                : <Anchor href={otherProps.to} target={target} {...otherProps}>{otherProps.children}</Anchor>
    )
}

export default CustomLink
