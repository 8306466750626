/**
 *
 * Divider
 *
 */

import React from 'react';
import styled from 'styled-components';
import { deviceGreaterThan } from '../../../styles/breakpoints';

const Divider = styled.hr`
/* below laptop breakpoint */
    background: #d6d6d6;
    margin: 20px 0px;
    margin-right: 21px;

@media ${deviceGreaterThan.laptop} {
    display: none;
}
`
export default Divider;
