import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const CutomButton = styled(({size, color, letterspacing, ...otherProps}) => <Button {...otherProps}/>)`
  display: ${props => props.display};
  width: ${props => props.width ? props.width : '212px'};
  min-width:${props => props.minwidth && props.minwidth};
  max-width:${props => props.maxwidth && props.maxwidth};
  height: ${props => props.height ? props.height : '40px'};
  padding:${props => props.padding && props.padding};
  border-radius:${props => props.borderradius && props.borderradius};
  transform: ${props => props.transform};
  background-color: ${props => props.backgroundcolor ? props.theme.colors[props.backgroundcolor] : props.theme.colors.CHQBOOK_BLUE};
  margin:${props => props.margin && props.margin};
  border:${props => props.border ? props.border : 'none'};
  color:  ${props =>  props.color ? props.theme.colors[props.color]: props.theme.colors.WHITE};
  opacity:${props => props.opacity && props.opacity};
  left: ${props => props.left && props.left};
  cursor:pointer;
  box-shadow: ${props => props.boxshadow && props.boxshadow };
  text-transform: ${props => props.texttransform};
  &:hover {
    box-shadow: ${props => props.boxshadow && props.boxshadow };
    background-color: ${props => props.hoverbackground ? props.theme.colors[props.hoverbackground] : props.theme.colors.CHQBOOK_BLUE_HOVER};
    color:  ${props => props.hovercolor ? props.theme.colors[props.hovercolor] : props.theme.colors.WHITE};
  };

  @media ${deviceLessThan.laptop} {
    margin:${props => props.mmargin && props.mmargin};
  }

  @media ${deviceLessThan.tablet} {
    display: ${props => props.sdisplay};
    margin:${props => props.smargin && props.smargin};
    min-width:${props => props.sminwidth && props.sminwidth};
    width:${props => props.swidth && props.swidth};
  }

  & .MuiButton-label {
    font-family: ${p => p.theme.fontFamily};
    display: flex;
    font-size: ${props => props.size ? props.theme.web.text.size[props.size] : props.theme.web.text.size.sm};
    color:  ${props =>  props.color ? props.theme.colors[props.color]: props.theme.colors.WHITE};
    font-weight:${props => props.weight ? props.theme.web.text.weight[props.weight] : props.theme.web.text.weight.bold};
    text-align: center;
    justify-content: ${props => props.justifycontent ? props.justifycontent : "space-around"};
    padding:${props => props.labelpadding};
    width:${props => props.labelwidth};
    margin: ${props => props.labelmargin};
    letter-spacing:${props => props.letterspacing && props.letterspacing};
    line-height:${props => props.lineheight && props.lineheight};
    @media ${deviceLessThan.tablet} {
      font-size: ${props => props.size ? props.theme.mobile.text.size[props.size] : props.theme.mobile.text.size.sm};
    }
  }
`;

export default CutomButton;
