/**
 *
 * Heading
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { deviceLessThan, deviceGreaterThan } from '../../../styles/breakpoints';

const Heading = styled.h1`
  font-family: ${p => p.family ? p.theme[p.family] : p.theme.fontFamily};
  font-size: ${p => p.size ? p.theme.web.heading.size[p.size] : p.theme.web.heading.size.md};
  font-weight: ${p => p.weight ? p.theme.web.heading.weight[p.weight] : p.theme.web.heading.weight.medium};
  letter-spacing: ${p=> p.spacing};
  color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.CHQBOOK_BLUE};
  text-align: ${p => p.align};
  opacity:${p => p.opacity};
  line-height: ${p => p.lineHeight && `${p.lineHeight}`};
  margin: ${p => p.margin ? p.margin: '0'};
  padding:${props => props.padding && props.padding };
  max-width:${props => props.maxWidth && props.maxWidth};
  align-self:${props => props.alignSelf && props.alignSelf};
  flex:${props => props.flex && props.flex};
  @media ${deviceLessThan.laptop} {
        display: ${props => props.mDisplay && props.mDisplay};
        font-size: ${p => p.size ? p.theme.mobile.heading.size[p.size] : p.theme.mobile.heading.size.md};
        font-weight: ${p => p.weight ? p.theme.mobile.heading.weight[p.weight] : p.theme.mobile.heading.weight.bold};
    }
    @media ${deviceLessThan.tablet} {
        padding: ${p => p.sPadding};
        margin: ${p => p.sMargin ? `${p.sMargin}` : '0'};
        text-align: ${p => p.sAlign};
    }
`
export default Heading;
