import React from 'react'
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { deviceLessThan } from '../../../styles/breakpoints';

export const CustomDrawer = styled(props => <Drawer {...props} />)`

    &.MuiDrawer-root {
        z-index: 200 !important;
    }

    & .MuiDrawer-paper {
        width: 65%;
        margin-top: 70px;
    }
`;
